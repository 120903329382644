<template>
    <div class="sidebar-page-container">
        <div class="auto-container">
            <div class="upper-info-box">
                <div class="row">
                    <div class="about-property col-lg-8 col-md-12 col-sm-12">
                        <h2>{{ property.title }}</h2>
                        <div class="location">
                            <i class="la la-map-marker"></i>
                            {{
                                "عمان - " +
                                property.governorate +
                                " - " +
                                property.state +
                                (property.area ? " - " + property.area : "") +
                                (property.street
                                    ? " - " + property.street
                                    : "") +
                                (property.landmark
                                    ? " - " + property.landmark
                                    : "")
                            }}
                        </div>
                        <ul class="property-info clearfix">
                            <li>
                                <i class="flaticon-users"></i>
                                {{ property.guests }} أشخاص
                            </li>
                            <li>
                                <i class="flaticon-bed"></i
                                >{{ property.beds }} أسرة
                            </li>
                            <li>
                                <i class="la la-wifi"></i>
                                {{
                                    property.wifi != "0" ? "متوفر" : "غير متوفر"
                                }}
                            </li>
                            <li>
                                <i class="flaticon-bathtub"></i>
                                {{ property.bathrooms }} حمامات
                            </li>
                        </ul>
                    </div>
                    <div class="price-column col-lg-4 col-md-12 col-sm-12">
                        <div class="price">{{ property.price_1 }} ريال</div>
                    </div>
                </div>
            </div>
            <div class="row clearfix">
                <!--Content Side-->
                <div class="content-side col-lg-8 col-md-12 col-sm-12">
                    <div class="property-detail">
                        <div class="upper-box">
                            <div class="carousel-outer">
                                <ul
                                    class="
                                        image-carousel
                                        owl-carousel owl-theme
                                    "
                                ></ul>

                                <ul
                                    class="
                                        thumbs-carousel
                                        owl-carousel owl-theme
                                    "
                                ></ul>
                            </div>
                            <ul class="info clearfix rate">
                                <li class="text-left">
                                    <div class="rating text-danger">
                                        <span
                                            :class="
                                                property.average_overall_rating >=
                                                1
                                                    ? 'la la-star'
                                                    : 'la la-star-o'
                                            "
                                        ></span>
                                        <span
                                            :class="
                                                property.average_overall_rating >=
                                                2
                                                    ? 'la la-star'
                                                    : 'la la-star-o'
                                            "
                                        ></span>
                                        <span
                                            :class="
                                                property.average_overall_rating >=
                                                3
                                                    ? 'la la-star'
                                                    : 'la la-star-o'
                                            "
                                        ></span>
                                        <span
                                            :class="
                                                property.average_overall_rating >=
                                                4
                                                    ? 'la la-star'
                                                    : 'la la-star-o'
                                            "
                                        ></span>
                                        <span
                                            :class="
                                                property.average_overall_rating >=
                                                5
                                                    ? 'la la-star'
                                                    : 'la la-star-o'
                                            "
                                        ></span>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <!-- Property Features -->
                        <div class="property-features">
                            <h3>الأسعار</h3>
                            <div class="row">
                                <table class="table table-striped w-100 text-center">
                                    <thead class="thead-inverse">
                                        <tr>
                                            <th style="width: 100px">اليوم</th>
                                            <th>
                                                الفترة الاولى ({{
                                                    property.note_1
                                                }})
                                            </th>
                                            <th v-if="property.price_2">
                                                الفترة الثانية ({{
                                                    property.note_2
                                                }})
                                            </th>
                                            <th v-if="property.price_3">
                                                الفترة الثالثة ({{
                                                    property.note_3
                                                }})
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td scope="row">السعر العادى</td>
                                            <td>{{ property.price_1 }}</td>
                                            <td v-if="property.price_2">
                                                {{ property.price_2 }}
                                            </td>
                                            <td v-if="property.price_3">
                                                {{ property.price_3 }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th colspan="4" class="text-center">
                                                الأسعار الخاصة
                                            </th>
                                        </tr>
                                        <template
                                            v-if="
                                                property.specialPrices.length >
                                                0
                                            "
                                        >
                                            <tr
                                                v-for="(
                                                    special, index
                                                ) in property.specialPrices"
                                                :key="index"
                                            >
                                                <td scope="row">
                                                    {{ special.date }}
                                                </td>
                                                <td>{{ special.price_1 }}</td>
                                                <td v-if="property.price_2">
                                                    {{ special.price_2 }}
                                                </td>
                                                <td v-if="property.price_3">
                                                    {{ special.price_3 }}
                                                </td>
                                            </tr>
                                        </template>
                                        <tr v-else>
                                            <td colspan="4" class="text-center">
                                                لا توجد أسعار خاصة
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ul class="list-style-one w-100">
                                    <li
                                        v-if="
                                            property.cancelation_deduction > 0
                                        "
                                    >
                                        نسبة الخصم عند إلغاء الحجز:
                                        {{ property.cancelation_deduction }}%
                                    </li>
                                    <li v-else>
                                        إلغاء الحجز بعد الموافقة مجانى
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div v-if="property.details" class="lower-content">
                            <h3>التفاصيل</h3>
                            <p>{{ property.details }}</p>
                        </div>

                        <!-- Property Features -->
                        <div class="property-features">
                            <h3>المواصفات</h3>
                            <ul class="list-style-one">
                                <li>عدد الغرف: {{ property.rooms }}</li>
                                <li>عدد الأسرة: {{ property.beds }}</li>
                                <li>
                                    الأسرة المزدوجة: {{ property["dual_beds"] }}
                                </li>
                                <li>
                                    الأسرة الفردية:
                                    {{ property["single_beds"] }}
                                </li>
                                <li>الحمامات: {{ property.bathrooms }}</li>
                                <li>
                                    عدد الضيوف المتاحين: {{ property.guests }}
                                </li>
                            </ul>
                        </div>

                        <!-- Property Features -->
                        <div class="property-features">
                            <h3>المرافق الداخلية</h3>
                            <ul class="list-style-one">
                                <li
                                    :class="{
                                        'not-active': !parseInt(
                                            property.air_conditioning
                                        ),
                                    }"
                                >
                                    مكيف هواء
                                </li>
                                <li
                                    :class="{
                                        'not-active': !parseInt(
                                            property.laundry
                                        ),
                                    }"
                                >
                                    غسيل ملابس
                                </li>
                                <li
                                    :class="{
                                        'not-active': !parseInt(property.sauna),
                                    }"
                                >
                                    ساونا
                                </li>
                                <li
                                    :class="{
                                        'not-active': !parseInt(
                                            property.swimming_pool
                                        ),
                                    }"
                                >
                                    مسبح
                                </li>
                                <li
                                    :class="{
                                        'not-active': !parseInt(property.wifi),
                                    }"
                                >
                                    واى فاى
                                </li>
                                <li
                                    :class="{
                                        'not-active': !parseInt(property.tv),
                                    }"
                                >
                                    تليفزيون
                                </li>
                                <li
                                    :class="{
                                        'not-active': !parseInt(
                                            property.parking
                                        ),
                                    }"
                                >
                                    موقف سيارات
                                </li>
                                <li
                                    :class="{
                                        'not-active': !parseInt(
                                            property.minibar
                                        ),
                                    }"
                                >
                                    ثلاجة مشروبات
                                </li>
                                <li
                                    :class="{
                                        'not-active': !parseInt(
                                            property.kitchen
                                        ),
                                    }"
                                >
                                    مطبخ
                                </li>
                                <li
                                    :class="{
                                        'not-active': !parseInt(
                                            property.minikitchen
                                        ),
                                    }"
                                >
                                    مطبخ صغير
                                </li>
                            </ul>
                        </div>

                        <!-- Property Features -->
                        <div class="property-features">
                            <h3>المرافق الخارجية</h3>
                            <ul class="list-style-one">
                                <li
                                    :class="{
                                        'not-active': !parseInt(
                                            property.balcony
                                        ),
                                    }"
                                >
                                    شرفة
                                </li>
                                <li
                                    :class="{
                                        'not-active': !parseInt(
                                            property.garden_view
                                        ),
                                    }"
                                >
                                    إطلالة على الحديقة
                                </li>
                                <li
                                    :class="{
                                        'not-active': !parseInt(
                                            property.beach_view
                                        ),
                                    }"
                                >
                                    إطلالة على البحر
                                </li>
                                <li
                                    :class="{
                                        'not-active': !parseInt(
                                            property.kids_area
                                        ),
                                    }"
                                >
                                    منطقة ألعاب أطفال
                                </li>
                                <li
                                    :class="{
                                        'not-active': !parseInt(
                                            property.playground
                                        ),
                                    }"
                                >
                                    ملعب
                                </li>
                                <li
                                    :class="{
                                        'not-active': !parseInt(
                                            property.barbecue_area
                                        ),
                                    }"
                                >
                                    منطقة شوى
                                </li>
                            </ul>
                        </div>

                        <!-- Property Features -->
                        <div class="property-features">
                            <h3>مسموح بالاتى</h3>
                            <ul class="list-style-one">
                                <li
                                    :class="{
                                        'not-active': !parseInt(
                                            property.smoking
                                        ),
                                    }"
                                >
                                    التدخين
                                </li>
                                <li
                                    :class="{
                                        'not-active': !parseInt(property.kids),
                                    }"
                                >
                                    إصطحاب الأطفال
                                </li>
                                <li
                                    :class="{
                                        'not-active': !parseInt(property.pets),
                                    }"
                                >
                                    إصطحاب الحيوانات
                                </li>
                                <li
                                    :class="{
                                        'not-active': !parseInt(
                                            property.parties
                                        ),
                                    }"
                                >
                                    الحفلات
                                </li>
                            </ul>
                        </div>

                        <!-- Property Features -->
                        <div class="property-features">
                            <h3>الموقع على الخريطة</h3>
                            <div id="map-wrapper">
                                <GoogleMap
                                    :api-key="$googleMapsApiKey"
                                    style="width: 100%; height: 500px"
                                    :center="{
                                        lat: parseFloat(property.lat),
                                        lng: parseFloat(property.lng),
                                    }"
                                    :zoom="7"
                                >
                                    <Marker
                                        :options="{
                                            position: {
                                                lat: parseFloat(property.lat),
                                                lng: parseFloat(property.lng),
                                            },
                                        }"
                                    />
                                </GoogleMap>
                            </div>
                        </div>

                        <!-- Review Box -->
                        <div class="review-area">
                            <!--Reviews Container-->
                            <div class="reviews-container">
                                <h3>تقييمات العملاء</h3>
                                <!--Reviews-->
                                <template v-if="property.reviews.data.length">
                                    <article
                                        v-for="review in property.reviews.data"
                                        :key="review.id"
                                        class="review-box row"
                                    >
                                        <div class="thumb-box">
                                            <figure class="thumb">
                                                <img
                                                    :src="review.user.image_url"
                                                    alt=""
                                                />
                                            </figure>
                                        </div>
                                        <div class="content-box col-8">
                                            <div class="name">
                                                {{ review.user.name }}
                                            </div>
                                            <span class="date"
                                                ><i class="la la-calendar"></i>
                                                {{
                                                    handleCreatedAt(
                                                        review.created_at
                                                    )
                                                }}</span
                                            >

                                            <div class="text">
                                                {{ review.comment }}
                                            </div>
                                        </div>

                                        <div class="col-4">
                                            <div class="rating d-flex">
                                                <div
                                                    class="
                                                        text
                                                        align-self-center
                                                    "
                                                >
                                                    رضاك بشكل عام:
                                                </div>

                                                <div class="align-self-center">
                                                    <span
                                                        v-if="
                                                            review.general_satisfy_rating >=
                                                            1
                                                        "
                                                        class="la la-star"
                                                    ></span
                                                    ><span
                                                        v-else
                                                        class="la la-star-o"
                                                    ></span>
                                                    <span
                                                        v-if="
                                                            review.general_satisfy_rating >=
                                                            2
                                                        "
                                                        class="la la-star"
                                                    ></span
                                                    ><span
                                                        v-else
                                                        class="la la-star-o"
                                                    ></span>
                                                    <span
                                                        v-if="
                                                            review.general_satisfy_rating >=
                                                            3
                                                        "
                                                        class="la la-star"
                                                    ></span
                                                    ><span
                                                        v-else
                                                        class="la la-star-o"
                                                    ></span>
                                                    <span
                                                        v-if="
                                                            review.general_satisfy_rating >=
                                                            4
                                                        "
                                                        class="la la-star"
                                                    ></span
                                                    ><span
                                                        v-else
                                                        class="la la-star-o"
                                                    ></span>
                                                    <span
                                                        v-if="
                                                            review.general_satisfy_rating >=
                                                            5
                                                        "
                                                        class="la la-star"
                                                    ></span
                                                    ><span
                                                        v-else
                                                        class="la la-star-o"
                                                    ></span>
                                                </div>
                                            </div>
                                            <div class="rating rating-2 d-flex">
                                                <div
                                                    class="
                                                        text
                                                        align-self-center
                                                    "
                                                >
                                                    موظف الإستقبال:
                                                </div>

                                                <div class="align-self-center">
                                                    <span
                                                        v-if="
                                                            review.reception_rating >=
                                                            1
                                                        "
                                                        class="la la-star"
                                                    ></span
                                                    ><span
                                                        v-else
                                                        class="la la-star-o"
                                                    ></span>
                                                    <span
                                                        v-if="
                                                            review.reception_rating >=
                                                            2
                                                        "
                                                        class="la la-star"
                                                    ></span
                                                    ><span
                                                        v-else
                                                        class="la la-star-o"
                                                    ></span>
                                                    <span
                                                        v-if="
                                                            review.reception_rating >=
                                                            3
                                                        "
                                                        class="la la-star"
                                                    ></span
                                                    ><span
                                                        v-else
                                                        class="la la-star-o"
                                                    ></span>
                                                    <span
                                                        v-if="
                                                            review.reception_rating >=
                                                            4
                                                        "
                                                        class="la la-star"
                                                    ></span
                                                    ><span
                                                        v-else
                                                        class="la la-star-o"
                                                    ></span>
                                                    <span
                                                        v-if="
                                                            review.reception_rating >=
                                                            5
                                                        "
                                                        class="la la-star"
                                                    ></span
                                                    ><span
                                                        v-else
                                                        class="la la-star-o"
                                                    ></span>
                                                </div>
                                            </div>
                                            <div class="rating rating-3 d-flex">
                                                <div
                                                    class="
                                                        text
                                                        align-self-center
                                                    "
                                                >
                                                    الأثاث والمرافق:
                                                </div>

                                                <div class="align-self-center">
                                                    <span
                                                        v-if="
                                                            review.assets_rating >=
                                                            1
                                                        "
                                                        class="la la-star"
                                                    ></span
                                                    ><span
                                                        v-else
                                                        class="la la-star-o"
                                                    ></span>
                                                    <span
                                                        v-if="
                                                            review.assets_rating >=
                                                            2
                                                        "
                                                        class="la la-star"
                                                    ></span
                                                    ><span
                                                        v-else
                                                        class="la la-star-o"
                                                    ></span>
                                                    <span
                                                        v-if="
                                                            review.assets_rating >=
                                                            3
                                                        "
                                                        class="la la-star"
                                                    ></span
                                                    ><span
                                                        v-else
                                                        class="la la-star-o"
                                                    ></span>
                                                    <span
                                                        v-if="
                                                            review.assets_rating >=
                                                            4
                                                        "
                                                        class="la la-star"
                                                    ></span
                                                    ><span
                                                        v-else
                                                        class="la la-star-o"
                                                    ></span>
                                                    <span
                                                        v-if="
                                                            review.assets_rating >=
                                                            5
                                                        "
                                                        class="la la-star"
                                                    ></span
                                                    ><span
                                                        v-else
                                                        class="la la-star-o"
                                                    ></span>
                                                </div>
                                            </div>
                                            <div class="rating rating-4 d-flex">
                                                <div
                                                    class="
                                                        text
                                                        align-self-center
                                                    "
                                                >
                                                    النظافة:
                                                </div>

                                                <div class="align-self-center">
                                                    <span
                                                        v-if="
                                                            review.clean_rating >=
                                                            1
                                                        "
                                                        class="la la-star"
                                                    ></span
                                                    ><span
                                                        v-else
                                                        class="la la-star-o"
                                                    ></span>
                                                    <span
                                                        v-if="
                                                            review.clean_rating >=
                                                            2
                                                        "
                                                        class="la la-star"
                                                    ></span
                                                    ><span
                                                        v-else
                                                        class="la la-star-o"
                                                    ></span>
                                                    <span
                                                        v-if="
                                                            review.clean_rating >=
                                                            3
                                                        "
                                                        class="la la-star"
                                                    ></span
                                                    ><span
                                                        v-else
                                                        class="la la-star-o"
                                                    ></span>
                                                    <span
                                                        v-if="
                                                            review.clean_rating >=
                                                            4
                                                        "
                                                        class="la la-star"
                                                    ></span
                                                    ><span
                                                        v-else
                                                        class="la la-star-o"
                                                    ></span>
                                                    <span
                                                        v-if="
                                                            review.clean_rating >=
                                                            5
                                                        "
                                                        class="la la-star"
                                                    ></span
                                                    ><span
                                                        v-else
                                                        class="la la-star-o"
                                                    ></span>
                                                </div>
                                            </div>
                                            <div class="rating rating-5 d-flex">
                                                <div
                                                    class="
                                                        text
                                                        align-self-center
                                                    "
                                                >
                                                    الموقع:
                                                </div>

                                                <div class="align-self-center">
                                                    <span
                                                        v-if="
                                                            review.location_rating >=
                                                            1
                                                        "
                                                        class="la la-star"
                                                    ></span
                                                    ><span
                                                        v-else
                                                        class="la la-star-o"
                                                    ></span>
                                                    <span
                                                        v-if="
                                                            review.location_rating >=
                                                            2
                                                        "
                                                        class="la la-star"
                                                    ></span
                                                    ><span
                                                        v-else
                                                        class="la la-star-o"
                                                    ></span>
                                                    <span
                                                        v-if="
                                                            review.location_rating >=
                                                            3
                                                        "
                                                        class="la la-star"
                                                    ></span
                                                    ><span
                                                        v-else
                                                        class="la la-star-o"
                                                    ></span>
                                                    <span
                                                        v-if="
                                                            review.location_rating >=
                                                            4
                                                        "
                                                        class="la la-star"
                                                    ></span
                                                    ><span
                                                        v-else
                                                        class="la la-star-o"
                                                    ></span>
                                                    <span
                                                        v-if="
                                                            review.location_rating >=
                                                            5
                                                        "
                                                        class="la la-star"
                                                    ></span
                                                    ><span
                                                        v-else
                                                        class="la la-star-o"
                                                    ></span>
                                                </div>
                                            </div>
                                            <div class="rating rating-6 d-flex">
                                                <div
                                                    class="
                                                        text
                                                        align-self-center
                                                    "
                                                >
                                                    السعر:
                                                </div>

                                                <div class="align-self-center">
                                                    <span
                                                        v-if="
                                                            review.price_rating >=
                                                            1
                                                        "
                                                        class="la la-star"
                                                    ></span
                                                    ><span
                                                        v-else
                                                        class="la la-star-o"
                                                    ></span>
                                                    <span
                                                        v-if="
                                                            review.price_rating >=
                                                            2
                                                        "
                                                        class="la la-star"
                                                    ></span
                                                    ><span
                                                        v-else
                                                        class="la la-star-o"
                                                    ></span>
                                                    <span
                                                        v-if="
                                                            review.price_rating >=
                                                            3
                                                        "
                                                        class="la la-star"
                                                    ></span
                                                    ><span
                                                        v-else
                                                        class="la la-star-o"
                                                    ></span>
                                                    <span
                                                        v-if="
                                                            review.price_rating >=
                                                            4
                                                        "
                                                        class="la la-star"
                                                    ></span
                                                    ><span
                                                        v-else
                                                        class="la la-star-o"
                                                    ></span>
                                                    <span
                                                        v-if="
                                                            review.price_rating >=
                                                            5
                                                        "
                                                        class="la la-star"
                                                    ></span
                                                    ><span
                                                        v-else
                                                        class="la la-star-o"
                                                    ></span>
                                                </div>
                                            </div>
                                        </div>
                                    </article>

                                    <div class="col-12 text-center mt-2">
                                        <button
                                            v-if="moreReviews"
                                            @click="
                                                getReviews(
                                                    currentReviewsPage + 1
                                                )
                                            "
                                            class="
                                                theme-btn
                                                btn-style-one
                                                text-white
                                            "
                                        >
                                            <i
                                                class="fa fa-plus"
                                                aria-hidden="true"
                                            ></i>
                                            المزيد
                                        </button>
                                    </div>
                                </template>
                                <template v-else>
                                    <article class="text-center">
                                        <p>لا توجد تقييمات</p>
                                    </article>
                                </template>
                            </div>
                        </div>

                        <!-- Review Comment Form -->
                        <div class="review-comment-form">
                            <h3>اترك تعليقك</h3>
                            <form @submit.prevent="submitReview()">
                                <div class="row">
                                    <div
                                        class="
                                            form-group
                                            col-lg-12 col-md-12 col-sm-12
                                        "
                                    >
                                        <textarea
                                            name="message"
                                            placeholder="Massage"
                                            v-model="review.comment"
                                        ></textarea>
                                    </div>
                                    <div
                                        class="
                                            form-group
                                            col-lg-6 col-md-6 col-sm-6
                                        "
                                    >
                                        <div class="rating-box">
                                            <div class="text">
                                                مدى رضاك بشكل عام:
                                            </div>
                                            <div class="rating">
                                                <a
                                                    @click.prevent="
                                                        review.general_satisfy_rating = 1
                                                    "
                                                    href="#"
                                                    ><span
                                                        :class="
                                                            review.general_satisfy_rating >=
                                                            1
                                                                ? 'la la-star'
                                                                : 'la la-star-o'
                                                        "
                                                    ></span
                                                ></a>
                                                <a
                                                    @click.prevent="
                                                        review.general_satisfy_rating = 2
                                                    "
                                                    href="#"
                                                    ><span
                                                        :class="
                                                            review.general_satisfy_rating >=
                                                            2
                                                                ? 'la la-star'
                                                                : 'la la-star-o'
                                                        "
                                                    ></span
                                                ></a>
                                                <a
                                                    @click.prevent="
                                                        review.general_satisfy_rating = 3
                                                    "
                                                    href="#"
                                                    ><span
                                                        :class="
                                                            review.general_satisfy_rating >=
                                                            3
                                                                ? 'la la-star'
                                                                : 'la la-star-o'
                                                        "
                                                    ></span
                                                ></a>
                                                <a
                                                    @click.prevent="
                                                        review.general_satisfy_rating = 4
                                                    "
                                                    href="#"
                                                    ><span
                                                        :class="
                                                            review.general_satisfy_rating >=
                                                            4
                                                                ? 'la la-star'
                                                                : 'la la-star-o'
                                                        "
                                                    ></span
                                                ></a>
                                                <a
                                                    @click.prevent="
                                                        review.general_satisfy_rating = 5
                                                    "
                                                    href="#"
                                                    ><span
                                                        :class="
                                                            review.general_satisfy_rating >=
                                                            5
                                                                ? 'la la-star'
                                                                : 'la la-star-o'
                                                        "
                                                    ></span
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="
                                            form-group
                                            col-lg-6 col-md-6 col-sm-6
                                        "
                                    >
                                        <div class="rating-box">
                                            <div class="text">
                                                موظف الإستقبال:
                                            </div>
                                            <div class="rating">
                                                <a
                                                    @click.prevent="
                                                        review.reception_rating = 1
                                                    "
                                                    href="#"
                                                    ><span
                                                        :class="
                                                            review.reception_rating >=
                                                            1
                                                                ? 'la la-star'
                                                                : 'la la-star-o'
                                                        "
                                                    ></span
                                                ></a>
                                                <a
                                                    @click.prevent="
                                                        review.reception_rating = 2
                                                    "
                                                    href="#"
                                                    ><span
                                                        :class="
                                                            review.reception_rating >=
                                                            2
                                                                ? 'la la-star'
                                                                : 'la la-star-o'
                                                        "
                                                    ></span
                                                ></a>
                                                <a
                                                    @click.prevent="
                                                        review.reception_rating = 3
                                                    "
                                                    href="#"
                                                    ><span
                                                        :class="
                                                            review.reception_rating >=
                                                            3
                                                                ? 'la la-star'
                                                                : 'la la-star-o'
                                                        "
                                                    ></span
                                                ></a>
                                                <a
                                                    @click.prevent="
                                                        review.reception_rating = 4
                                                    "
                                                    href="#"
                                                    ><span
                                                        :class="
                                                            review.reception_rating >=
                                                            4
                                                                ? 'la la-star'
                                                                : 'la la-star-o'
                                                        "
                                                    ></span
                                                ></a>
                                                <a
                                                    @click.prevent="
                                                        review.reception_rating = 5
                                                    "
                                                    href="#"
                                                    ><span
                                                        :class="
                                                            review.reception_rating >=
                                                            5
                                                                ? 'la la-star'
                                                                : 'la la-star-o'
                                                        "
                                                    ></span
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="
                                            form-group
                                            col-lg-6 col-md-6 col-sm-6
                                        "
                                    >
                                        <div class="rating-box">
                                            <div class="text">
                                                الأثاث والمرافق:
                                            </div>
                                            <div class="rating">
                                                <a
                                                    @click.prevent="
                                                        review.assets_rating = 1
                                                    "
                                                    href="#"
                                                    ><span
                                                        :class="
                                                            review.assets_rating >=
                                                            1
                                                                ? 'la la-star'
                                                                : 'la la-star-o'
                                                        "
                                                    ></span
                                                ></a>
                                                <a
                                                    @click.prevent="
                                                        review.assets_rating = 2
                                                    "
                                                    href="#"
                                                    ><span
                                                        :class="
                                                            review.assets_rating >=
                                                            2
                                                                ? 'la la-star'
                                                                : 'la la-star-o'
                                                        "
                                                    ></span
                                                ></a>
                                                <a
                                                    @click.prevent="
                                                        review.assets_rating = 3
                                                    "
                                                    href="#"
                                                    ><span
                                                        :class="
                                                            review.assets_rating >=
                                                            3
                                                                ? 'la la-star'
                                                                : 'la la-star-o'
                                                        "
                                                    ></span
                                                ></a>
                                                <a
                                                    @click.prevent="
                                                        review.assets_rating = 4
                                                    "
                                                    href="#"
                                                    ><span
                                                        :class="
                                                            review.assets_rating >=
                                                            4
                                                                ? 'la la-star'
                                                                : 'la la-star-o'
                                                        "
                                                    ></span
                                                ></a>
                                                <a
                                                    @click.prevent="
                                                        review.assets_rating = 5
                                                    "
                                                    href="#"
                                                    ><span
                                                        :class="
                                                            review.assets_rating >=
                                                            5
                                                                ? 'la la-star'
                                                                : 'la la-star-o'
                                                        "
                                                    ></span
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="
                                            form-group
                                            col-lg-6 col-md-6 col-sm-6
                                        "
                                    >
                                        <div class="rating-box">
                                            <div class="text">النظافة:</div>
                                            <div class="rating">
                                                <a
                                                    @click.prevent="
                                                        review.clean_rating = 1
                                                    "
                                                    href="#"
                                                    ><span
                                                        :class="
                                                            review.clean_rating >=
                                                            1
                                                                ? 'la la-star'
                                                                : 'la la-star-o'
                                                        "
                                                    ></span
                                                ></a>
                                                <a
                                                    @click.prevent="
                                                        review.clean_rating = 2
                                                    "
                                                    href="#"
                                                    ><span
                                                        :class="
                                                            review.clean_rating >=
                                                            2
                                                                ? 'la la-star'
                                                                : 'la la-star-o'
                                                        "
                                                    ></span
                                                ></a>
                                                <a
                                                    @click.prevent="
                                                        review.clean_rating = 3
                                                    "
                                                    href="#"
                                                    ><span
                                                        :class="
                                                            review.clean_rating >=
                                                            3
                                                                ? 'la la-star'
                                                                : 'la la-star-o'
                                                        "
                                                    ></span
                                                ></a>
                                                <a
                                                    @click.prevent="
                                                        review.clean_rating = 4
                                                    "
                                                    href="#"
                                                    ><span
                                                        :class="
                                                            review.clean_rating >=
                                                            4
                                                                ? 'la la-star'
                                                                : 'la la-star-o'
                                                        "
                                                    ></span
                                                ></a>
                                                <a
                                                    @click.prevent="
                                                        review.clean_rating = 5
                                                    "
                                                    href="#"
                                                    ><span
                                                        :class="
                                                            review.clean_rating >=
                                                            5
                                                                ? 'la la-star'
                                                                : 'la la-star-o'
                                                        "
                                                    ></span
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="
                                            form-group
                                            col-lg-6 col-md-6 col-sm-6
                                        "
                                    >
                                        <div class="rating-box">
                                            <div class="text">الموقع:</div>
                                            <div class="rating">
                                                <a
                                                    @click.prevent="
                                                        review.location_rating = 1
                                                    "
                                                    href="#"
                                                    ><span
                                                        :class="
                                                            review.location_rating >=
                                                            1
                                                                ? 'la la-star'
                                                                : 'la la-star-o'
                                                        "
                                                    ></span
                                                ></a>
                                                <a
                                                    @click.prevent="
                                                        review.location_rating = 2
                                                    "
                                                    href="#"
                                                    ><span
                                                        :class="
                                                            review.location_rating >=
                                                            2
                                                                ? 'la la-star'
                                                                : 'la la-star-o'
                                                        "
                                                    ></span
                                                ></a>
                                                <a
                                                    @click.prevent="
                                                        review.location_rating = 3
                                                    "
                                                    href="#"
                                                    ><span
                                                        :class="
                                                            review.location_rating >=
                                                            3
                                                                ? 'la la-star'
                                                                : 'la la-star-o'
                                                        "
                                                    ></span
                                                ></a>
                                                <a
                                                    @click.prevent="
                                                        review.location_rating = 4
                                                    "
                                                    href="#"
                                                    ><span
                                                        :class="
                                                            review.location_rating >=
                                                            4
                                                                ? 'la la-star'
                                                                : 'la la-star-o'
                                                        "
                                                    ></span
                                                ></a>
                                                <a
                                                    @click.prevent="
                                                        review.location_rating = 5
                                                    "
                                                    href="#"
                                                    ><span
                                                        :class="
                                                            review.location_rating >=
                                                            5
                                                                ? 'la la-star'
                                                                : 'la la-star-o'
                                                        "
                                                    ></span
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="
                                            form-group
                                            col-lg-6 col-md-6 col-sm-6
                                        "
                                    >
                                        <div class="rating-box">
                                            <div class="text">السعر:</div>
                                            <div class="rating">
                                                <a
                                                    @click.prevent="
                                                        review.price_rating = 1
                                                    "
                                                    href="#"
                                                    ><span
                                                        :class="
                                                            review.price_rating >=
                                                            1
                                                                ? 'la la-star'
                                                                : 'la la-star-o'
                                                        "
                                                    ></span
                                                ></a>
                                                <a
                                                    @click.prevent="
                                                        review.price_rating = 2
                                                    "
                                                    href="#"
                                                    ><span
                                                        :class="
                                                            review.price_rating >=
                                                            2
                                                                ? 'la la-star'
                                                                : 'la la-star-o'
                                                        "
                                                    ></span
                                                ></a>
                                                <a
                                                    @click.prevent="
                                                        review.price_rating = 3
                                                    "
                                                    href="#"
                                                    ><span
                                                        :class="
                                                            review.price_rating >=
                                                            3
                                                                ? 'la la-star'
                                                                : 'la la-star-o'
                                                        "
                                                    ></span
                                                ></a>
                                                <a
                                                    @click.prevent="
                                                        review.price_rating = 4
                                                    "
                                                    href="#"
                                                    ><span
                                                        :class="
                                                            review.price_rating >=
                                                            4
                                                                ? 'la la-star'
                                                                : 'la la-star-o'
                                                        "
                                                    ></span
                                                ></a>
                                                <a
                                                    @click.prevent="
                                                        review.price_rating = 5
                                                    "
                                                    href="#"
                                                    ><span
                                                        :class="
                                                            review.price_rating >=
                                                            5
                                                                ? 'la la-star'
                                                                : 'la la-star-o'
                                                        "
                                                    ></span
                                                ></a>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        class="
                                            form-group
                                            col-lg-6 col-md-6 col-sm-6
                                            text-right
                                        "
                                    >
                                        <button
                                            class="theme-btn btn-style-one"
                                            type="submit"
                                            name="submit-form"
                                        >
                                            إضافة
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <!--Sidebar Side-->
                <div class="sidebar-side col-lg-4 col-md-12 col-sm-12">
                    <aside class="sidebar default-sidebar">
                        <div class="sidebar-widget">
                            <router-link
                                :to="'/rent-property/' + property.id"
                                class="
                                    text-white text-center
                                    col-4
                                    theme-btn
                                    btn-style-one
                                "
                                ><i class="fa fa-check"></i> حجز
                            </router-link>
                        </div>

                        <!--search box-->
                        <div class="sidebar-widget sort-by">
                            <select
                                v-model="searchParams.order"
                                class="form-control"
                            >
                                <option value="0">ترتيب</option>
                                <option value="price-asc">
                                    سعر من الاقل للأعلى
                                </option>
                                <option value="price-desc">
                                    سعر من الاعلى للأقل
                                </option>
                            </select>
                        </div>

                        <!-- Categories -->
                        <div class="sidebar-widget search-properties">
                            <div class="sidebar-title">
                                <h3>بحث فى العقارات</h3>
                            </div>
                            <!-- Property Search Form -->
                            <div class="property-search-form style-three">
                                <form method="post">
                                    <div class="row no-gutters">
                                        <!-- Form Group -->
                                        <div class="form-group">
                                            <input
                                                v-model="searchParams.q"
                                                type="text"
                                                placeholder="بحث باسم العقار او العنوان"
                                            />
                                        </div>

                                        <!-- Form Group -->
                                        <div class="form-group">
                                            <select
                                                v-model="searchParams.category"
                                                class="custom-select-box"
                                            >
                                                <option value="0">
                                                    أى قسم
                                                </option>
                                                <option
                                                    v-for="category in categories"
                                                    :value="category.id"
                                                    :key="category.id"
                                                >
                                                    {{ category.title }}
                                                </option>
                                            </select>
                                        </div>

                                        <!-- Form Group -->
                                        <div class="form-group">
                                            <select
                                                v-model="searchParams.rooms"
                                                class="custom-select-box"
                                            >
                                                <option value="0">
                                                    عدد الغرف
                                                </option>
                                                <option value="1">
                                                    غرفة واحدة
                                                </option>
                                                <option value="2">
                                                    غرفتين
                                                </option>
                                                <option value="3">3 غرف</option>
                                                <option value="4">4 غرف</option>
                                                <option value="5">5 غرف</option>
                                            </select>
                                        </div>

                                        <!-- Form Group -->
                                        <div class="form-group">
                                            <select
                                                v-model="searchParams.guests"
                                                class="custom-select-box"
                                            >
                                                <option value="0">
                                                    عدد الضيوف
                                                </option>
                                                <option value="1">
                                                    شخص واحد
                                                </option>
                                                <option value="2">شخصين</option>
                                                <option value="3">
                                                    3 أشخاص
                                                </option>
                                                <option value="4">
                                                    4 أشخاص
                                                </option>
                                                <option value="5">
                                                    5 أشخاص
                                                </option>
                                                <option value="more">
                                                    أكثر
                                                </option>
                                            </select>
                                        </div>

                                        <!-- Form Group -->
                                        <div class="form-group">
                                            <select
                                                v-model="searchParams.bathrooms"
                                                class="custom-select-box"
                                            >
                                                <option value="0">
                                                    عدد الحمامات
                                                </option>
                                                <option value="1">
                                                    حمام واحد
                                                </option>
                                                <option value="2">
                                                    2 حمام
                                                </option>
                                                <option value="3">
                                                    3 حمامات
                                                </option>
                                                <option value="4">
                                                    4 حمامات
                                                </option>
                                                <option value="5">
                                                    5 حمامات
                                                </option>
                                            </select>
                                        </div>

                                        <!-- Form Group -->
                                        <div class="form-group">
                                            <div
                                                class="
                                                    range-slider-one
                                                    clearfix
                                                "
                                            >
                                                <label
                                                    >بحث بالسعر من - إلى</label
                                                >
                                                <div
                                                    class="price-range-slider"
                                                ></div>
                                                <div class="input">
                                                    <input
                                                        type="text"
                                                        class="price-amount"
                                                        name="field-name"
                                                        readonly
                                                        :value="
                                                            searchParams.price_from +
                                                            ' - ' +
                                                            searchParams.price_to
                                                        "
                                                    />
                                                </div>
                                                <div class="title">
                                                    ريال عمانى
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Form Group -->
                                        <div class="form-group row">
                                            <button
                                                type="button"
                                                @click.prevent="search()"
                                                class="theme-btn btn-style-one"
                                            >
                                                بحث
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <!-- End Form -->
                        </div>

                        <!-- Categories -->
                        <div class="sidebar-widget categories">
                            <div class="sidebar-title">
                                <h3>أقسام العقارات</h3>
                            </div>
                            <ul class="cat-list">
                                <li
                                    v-for="category in categories"
                                    :key="category.id"
                                >
                                    <router-link
                                        :to="
                                            '/properties?category=' +
                                            category.id
                                        "
                                        >{{ category.title }}
                                        <span>{{
                                            category.properties_count
                                        }}</span></router-link
                                    >
                                </li>
                            </ul>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { GoogleMap, Marker } from "vue3-google-map";
import moment from "moment";

export default {
    name: "property",
    components: { GoogleMap, Marker },
    data() {
        return {
            categories: [],
            property: {
                specialPrices: [],
                reviews: {
                    data: [],
                },
            },
            searchParams: {
                price_from: 0,
                price_to: 0,
                bathrooms: 0,
                category: 0,
                rooms: 0,
                guests: 0,
                order: "price-asc",
                title: "",
                address: "",
            },
            $sync1: null,
            $sync2: null,
            review: {
                general_satisfy_rating: 1,
                reception_rating: 1,
                assets_rating: 1,
                clean_rating: 1,
                location_rating: 1,
                price_rating: 1,
                comment: "",
            },
            moreReviews: false,
            currentReviewsPage: 1,
        };
    },
    beforeRouteEnter(to, from, next) {
        next(($this) => {
            if ($this.$route.params.id != $this.property.id) {
                $this.initialize();
            }
        });
    },
    methods: {
        search() {
            this.$router.push({
                name: "website.properties",
                query: this.searchParams,
            });
        },
        submitReview() {
            this.$axios
                .post("/my/review/" + this.property.id, this.review)
                .then((res) => {
                    this.review = {
                        general_satisfy_rating: 1,
                        reception_rating: 1,
                        assets_rating: 1,
                        clean_rating: 1,
                        location_rating: 1,
                        price_rating: 1,
                        comment: "",
                    };

                    this.property.reviews.data.push(res.data.review);
                });
        },
        getReviews(page = 1) {
            this.$axios
                .get("reviews/" + this.property.id + "?page=" + page)
                .then((response) => {
                    this.currentReviewsPage =
                        response.data.reviews.current_page;
                    this.moreReviews = !!response.data.reviews.next_page_url;

                    this.property.reviews.data.push(
                        ...response.data.reviews.data
                    );
                });
        },
        initialize() {
            this.$axios
                .get("/property/" + this.$route.params.id)
                .then((res) => {
                    this.property = res.data.property;

                    this.currentReviewsPage =
                        res.data.property.reviews.current_page;
                    this.moreReviews =
                        !!res.data.property.reviews.next_page_url;

                    this.categories = res.data.categories;
                })
                .then(() => {
                    if (!this.$sync1 || !this.$sync2) {
                        let $this = this;
                        this.$sync1 = $(".property-detail .image-carousel");
                        this.$sync2 = $(".property-detail .thumbs-carousel");

                        this.$sync1.html(this.html1);
                        this.$sync2.html(this.html2);

                        var flag = false,
                            duration = 500;

                        this.$sync1
                            .owlCarousel({
                                loop: false,
                                items: 1,
                                margin: 0,
                                nav: false,
                                navText: [
                                    '<span class="icon la la-angle-left"></span>',
                                    '<span class="icon la la-angle-right"></span>',
                                ],
                                dots: false,
                                autoplay: true,
                                autoplayTimeout: 5000,
                            })
                            .on("changed.owl.carousel", function (e) {
                                if (!flag) {
                                    flag = false;
                                    $this.$sync2.trigger("to.owl.carousel", [
                                        e.item.index,
                                        duration,
                                        true,
                                    ]);
                                    flag = false;
                                }
                            });

                        this.$sync2
                            .owlCarousel({
                                loop: false,
                                margin: 10,
                                items: 1,
                                nav: true,
                                navText: [
                                    '<span class="icon la la-arrow-circle-o-left"></span>',
                                    '<span class="icon la la-arrow-circle-o-right"></span>',
                                ],
                                dots: false,
                                center: false,
                                autoplay: true,
                                autoplayTimeout: 5000,
                                responsive: {
                                    0: {
                                        items: 2,
                                        autoWidth: false,
                                    },
                                    400: {
                                        items: 2,
                                        autoWidth: false,
                                    },
                                    600: {
                                        items: 3,
                                        autoWidth: false,
                                    },
                                    800: {
                                        items: 5,
                                        autoWidth: false,
                                    },
                                    1024: {
                                        items: 4,
                                        autoWidth: false,
                                    },
                                },
                            })

                            .on("click", ".owl-item", function () {
                                $this.$sync1.trigger("to.owl.carousel", [
                                    $(this).index(),
                                    duration,
                                    true,
                                ]);
                            })
                            .on("changed.owl.carousel", function (e) {
                                if (!flag) {
                                    flag = true;
                                    $this.$sync1.trigger("to.owl.carousel", [
                                        e.item.index,
                                        duration,
                                        true,
                                    ]);
                                    flag = false;
                                }
                            });
                    } else {
                        this.$sync1
                            .trigger("replace.owl.carousel", this.html1)
                            .trigger("refresh.owl.carousel");
                        this.$sync2
                            .trigger("replace.owl.carousel", this.html2)
                            .trigger("refresh.owl.carousel");
                    }

                    //LightBox / Fancybox
                    if ($(".lightbox-image").length) {
                        $(".lightbox-image").fancybox({
                            openEffect: "fade",
                            closeEffect: "fade",
                            helpers: {
                                media: {},
                            },
                        });
                    }
                });
        },
        handleCreatedAt(time) {
            return moment(time)
                .locale("AR")
                .format("dddd, Do MMMM  YYYY, h:mm:ss a");
        },
    },
    computed: {
        html1() {
            var html = "";

            this.property.images.forEach(function (image) {
                html += `<li><a href="${image.image}" class="lightbox-image" ><img src="${image.image}" alt="" /></a></li>`;
            });

            return html.length
                ? html
                : `<li><a href="${this.property.image_url}" class="lightbox-image" ><img src="${this.property.image_url}" alt="" /></a></li>`;
        },
        html2() {
            var html = "";

            this.property.images.forEach(function (image) {
                html += `<li><img src="${image.thumb}" alt="" /></li>`;
            });

            return html.length
                ? html
                : `<li><img src="${this.property.image_url}" alt="" /></li>`;
        },
    },
};
</script>

<style scoped>
.list-style-one li {
    float: right !important;
}

.list-style-one li.not-active:before {
    color: #ccc;
    content: "\f344";
}

.rating-2 {
    top: 25px !important;
}
.rating-3 {
    top: 50px !important;
}
.rating-4 {
    top: 75px !important;
}
.rating-5 {
    top: 100px !important;
}
.rating-6 {
    top: 125px !important;
}

.review-box {
    min-height: 165px;
}

.review-box.row .rating {
    width: 100%;
}

.review-box.row .rating .text {
    flex-grow: 1;
}

.rate {
    position: absolute;
    width: 100%;
    top: 23px;
    left: 18px;
    z-index: 1;
    font-size: 20px;
}
</style>